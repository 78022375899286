import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";
import FileUploader from "../../../components/FileUploader";
import './style.css';
import { useLocation, useNavigate } from "react-router-dom";


interface Product {
  id: number;
  name: string;
  description: string;
  productColor: string;
  productSize: string[];
}

interface GuideBox {
  guidelineBoxText: string;
  leftPercent: number;
  topPercent: number;
  widthPercent: number;
  heightPercent: number;
}

interface Part {
  name: string;
  [key: string]: any;
  colors: string[];
}

interface FormValues {
  name: string;
  parts: Part[];
}

const EditToolApparel: React.FC = () => {
  const [productCanvasId, setProductCanvasId] = useState<string>("");
  const [parts, setParts] = useState<Part[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedProductId, setSelectedProductId] = useState<number>(0);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productColors, setProductColors] = useState<string[]>([]);
  const [screen, setScreen] = useState<boolean>(false);
  const [emb, setEmb] = useState<boolean>(false);
    const [selectedCanvasStatus, setSelectedCanvasStatus] = useState<string>("all");
    const [searchValue, setSearchValue] = useState<string>("");
    const [productsPerPage, setProductsPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
      const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = Boolean(location.state?.detail);

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("Please enter Project Name"),
    })
  );

  const methods = useForm<FormValues>({ resolver: schemaResolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
  } = methods;

 useEffect(() => {
     if (selectedOption === "Images") {
       fetchProducts();
     }
   }, [selectedOption]);
 
   const fetchProducts = async () => {
     try {
       let url = `https://backend.inkox.com/api/product?page=${currentPage}&per_page=${productsPerPage}`;
   
       if (selectedCanvasStatus !== "all") {
         url += `&assignproduct=${selectedCanvasStatus}`;
       }
   
       if (searchValue) {
         url += `&name=${searchValue}`;
       }
   
       const response = await fetch(url);
       if (!response.ok) {
         throw new Error(`HTTP error! status: ${response.status}`);
       }
   
       const data = await response.json();
       setProducts(data.data);
       
       
       setTotalPages(data.last_page);
     } catch (error) {
       console.error("Error fetching products:", error);
     }
   };

   useEffect(() => {
    fetchProducts();
  }, [currentPage, selectedCanvasStatus, searchValue, productsPerPage]);
   useEffect(() => {
    if(selectedProductId){
      handleProductSelect(selectedProductId);
     }
  }, [products, selectedProductId]);

  useEffect(() => {
    if (isEditMode) {
      const detail = location.state.detail;
      setProductCanvasId(detail.name);
      setValue("name", detail.name);
      console.log("----------0--------");
      console.log(detail);
      console.log(detail.parts);

      if (detail.parts.length > 0) {
        const firstPart = JSON.parse(detail.parts);
        console.log(firstPart); // Check the parsed object structure
      
        // Assuming the structure of firstPart contains the productId within the object
        const productId = firstPart[0].productId;
        setSelectedProductId(productId);
        
        console.log(productId);
      } else {
        console.log('No parts available');
      }

// Alternatively, log the first part object to check its structure
console.log(detail.parts[0]);

      // Parse parts and set state
      try {
        const parsedParts = JSON.parse(detail.parts);
        const updatedParts = parsedParts.map((part: any) => {
          const description = JSON.parse(part.description);

          // Set screenPrinting and embroidery state
          console.log("--1-----",description);
          console.log(description.screenPrinting);
          console.log(description.embroidery);
          // if (description.screenPrinting){setScreen(true); part.screenPrinting=true;};
          // if (description.embroidery){setEmb(true);part.embroidery=true;};
          if (description.screenPrinting){
            setScreen(true);
            description.screenPrintingData=description.screenPrinting;
            console.log("screenPrintingData :",description.screenPrintingData);
            description.screenPrinting=true;
          };
          if (description.embroidery){
            setEmb(true);
            description.embroideryData=description.embroidery;
            description.embroidery=true;
          };

          return {
            ...part,
            ...description,
          };
        });

        setParts(updatedParts);
        setSelectedOption(detail.type || "");

        console.log("-------------");
        console.log(parts);
        console.log("-------------000");
        console.log(updatedParts);
        
      } catch (error) {
        console.error("Error parsing parts data:", error);
      }
    }
  }, [isEditMode, location.state, setValue]);

console.log("Default Product Id:", selectedProductId);

  

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
    setParts([]);
    setSelectedProduct(null);
    setProductColors([]);
  };
  

  const handleProductSelect = (productId: number) => {
    console.log("SelectedProduct:", productId);
    const product = products.find((p) => p.id === productId) || null;
    setSelectedProductId(productId);
    setSelectedProduct(product);
    console.log("product=",product);

    if (product) {
      const colors = Object.keys(JSON.parse(product.productColor || "{}"));
      console.log("ProductColors",colors);
      setProductColors(colors);
      propagateColorsToParts(colors);
    }
  };

  const propagateColorsToParts = (colors: string[]) => {
    setParts((prevParts) =>
      prevParts.map((part) => ({
        ...part,
        colors,
      }))
    );
  };


  const handleCheckboxChange = (
    partIndex: number,
    option: "screenPrinting" | "embroidery"
  ) => {
    if(option == "screenPrinting") {
      setScreen(true);
    } else if (option == "embroidery") {
      setEmb(true);
    } else {
      setScreen(true);
      setEmb(true);
    }

    setParts((prevParts) =>
      prevParts.map((part, index) =>
        index === partIndex
          ? { ...part, [option]: !part[option] }
          : part
      )
    );
  };

  const handleAddPart = () => {
    const partIndex = parts.length + 1; // Increment part index based on current parts
    setParts([
      ...parts,
      {
        name: `Part`,
        screenPrinting: false,
        embroidery: false,
        [`ProductImageComponent${partIndex}`]:
          selectedOption === "Masking"
            ? { image: "" }
            : { colors: [] },
        screenPrintingData: {
          [`CanvasComponent${partIndex}`]: {
            canvasSize: "dynamic",
          },
          [`DesignAreaComponent${partIndex}`]: {
            designAreaText: "",
            designableArea: {
              leftPercent: 0,
              topPercent: 0,
              widthPercent: 0,
              heightPercent: 0,
            },
            guideboxes: [
              {
                guidelineBoxText: "",
                leftPercent: 0,
                topPercent: 0,
                widthPercent: 0,
                heightPercent: 0,
              },
            ],
          },
        },
        embroideryData: {
          [`CanvasComponent${partIndex}`]: {
            canvasSize: "dynamic",
          },
          [`DesignAreaComponent${partIndex}`]: {
            designAreaText: "",
            designableArea: {
              leftPercent: 0,
              topPercent: 0,
              widthPercent: 0,
              heightPercent: 0,
            },
            guideboxes: [
              {
                guidelineBoxText: "",
                leftPercent: 0,
                topPercent: 0,
                widthPercent: 0,
                heightPercent: 0,
              },
            ],
          },
        },
        colors: productColors,
      },
    ]);
  };

    
  const handleFileUpload = (file: File, partIndex: number, color?: string) => {
    setParts((prevParts) => {
      const updatedParts = [...prevParts];
      const partKey = `ProductImageComponent${partIndex + 1}`;
  
      if (!updatedParts[partIndex][partKey]) {
        updatedParts[partIndex][partKey] = { image: "", colors: {} };
      }
  
      if (color) {
        updatedParts[partIndex][partKey].colors[color] = file;
      } else {
        updatedParts[partIndex][partKey].image = file;
      }
      return updatedParts;
    });
  };
  

  const handleRemovePart = (index: number) => {
    const updatedParts = [...parts];
    updatedParts.splice(index, 1);
    setParts(updatedParts);
  };

  const handleAddGuideBox = (partIndex: number,datavalue: string) => {
    const updatedParts = [...parts];
    const designAreaKey = `DesignAreaComponent${partIndex + 1}`;
    console.log("------------");
    console.log(partIndex);
    console.log(updatedParts[partIndex]);
    console.log(updatedParts[partIndex][datavalue][designAreaKey].guideboxes);
    updatedParts[partIndex][datavalue][designAreaKey].guideboxes.push({
      guidelineBoxText: "",
      leftPercent: 0,
      topPercent: 0,
      widthPercent: 0,
      heightPercent: 0,
    });
    console.log("Parts",updatedParts)
    setParts(updatedParts);
  };

  const handleRemoveGuideBox = (partIndex: number, boxIndex: number, dataValue: string) => {
    setParts((prevParts) => {
      const updatedParts = [...prevParts];
      const designAreaKey = `DesignAreaComponent${partIndex + 1}`;
      const guideboxes = updatedParts[partIndex][dataValue][designAreaKey].guideboxes;
       
      // Remove the specific guidebox
      if (guideboxes && guideboxes.length > boxIndex) {
        guideboxes.splice(boxIndex, 1);
      }
  
      return updatedParts;
    });
  };
  
  
  

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    // const updatedParts = parts.map((part, index) => {
    //   const partKey = `ProductImageComponent${index + 1}`;
    //   const productImageComponent = part[partKey]?.colors
    //     ? part[partKey].colors.reduce((acc: any, color: string) => {
    //         acc[color] = part[partKey][color];
    //         return acc;
    //       }, {})
    //     : { image: part[partKey]?.image || "" };

    //   return {
    //     name: part.name,
    //     description: JSON.stringify(part),
    //     ...productImageComponent,
    //   };
    // });

    // console.log("------------Submit------------");
    // console.log(data);
    // const jsonData = {
    //   name: data.name,
    //   parts: JSON.stringify(data),
    //   type: selectedOption,
    // };

    const encodeFileToBase64 = (file: File): Promise<string | null> => {
      return new Promise((resolve, reject) => {
        if (!(file instanceof File)) {
          resolve(null);
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    };
  
    const updatedParts = await Promise.all(
      data.parts.map(async (part, index) => {
        const partKey = `ProductImageComponent${index + 1}`;
        let imageOrColors = {};
  
        if (selectedOption === "Masking") {
          const file = parts[index][partKey]?.image;
          imageOrColors = {
            image: file instanceof File ? await encodeFileToBase64(file) : file,
          };
        } else if (selectedOption === "Images") {
          const colors = parts[index][partKey]?.colors || {};
          const colorsWithImages: Record<string, string | null> = {};
  
          for (const [color, image] of Object.entries(colors)) {
            colorsWithImages[color] =
              (image as File) instanceof File
                ? await encodeFileToBase64(image as File)
                : (image as string | null);
          }
          imageOrColors = { colors: colorsWithImages };
        }

        let canvasType = "";
        if (screen && emb) {
          canvasType = "screenPrinting, embroidery";
        } else if (screen) {
          canvasType = "screenPrinting";
        } else if (emb) {
          canvasType = "embroidery";
        }
  
        return {
          name: part.name,
          description: JSON.stringify({
            screenPrinting: part.screenPrintingData || null,
            embroidery: part.embroideryData || null,
          }),
          canvasType,
          ...imageOrColors,
        };
      })
    );
  
    const jsonData = {
      name: data.name,
      parts: updatedParts,
      type: selectedOption,
    };
    const apiUrl = isEditMode
      ? `https://backend.inkox.com/api/tool-apparel/update/${location.state.detail.id}`
      : `https://backend.inkox.com/api/tool-apparel`;
    // const apiUrl = isEditMode
    //   ? `http://127.0.0.1:8000/api/tool-apparel/update/${location.state.detail.name}`
    //   : `http://127.0.0.1:8000/api/tool-apparel`;

    const method = isEditMode ? "PUT" : "POST";

    try {
      const response = await fetch(apiUrl, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response from API:", response.status, errorText);
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      alert(
        isEditMode
          ? "Tool Apparel updated successfully!"
          : "Tool Apparel created successfully!"
      );
      // navigate("/apps/ecommerce/tool-apparel-detail");
    } catch (error) {
      console.error("Error saving tool apparel:", error);
      alert("Error saving Tool Apparel");
    }
  };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value.toLowerCase();
      setSearchValue(searchValue);
    
  
      if (searchValue === "") {
        // Clear selected product if search is cleared
        setSelectedProduct(null);
        return;
      }
      // Automatically select the first matching product
      const matchingProduct = products.find((product) =>
        product.name.toLowerCase().includes(searchValue)
      );
    
      if (matchingProduct) {
        setSelectedProduct(matchingProduct); // Set the full product object
        handleProductSelect(matchingProduct.id);
      } else {
        setSelectedProduct(null); // Reset selection if no match is found
        handleProductSelect(0);
      }
    };
    
  
  
    // Handle canvas status change
    const handleCanvasStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedCanvasStatus(e.target.value);
      setCurrentPage(1);
    };
  
    // Handle pagination change
  
  
    // Handle products per page change
    const handleProductsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setProductsPerPage(Number(e.target.value));
      setCurrentPage(1); // Reset to first page when changing products per page
    };


 
console.log("Parts",parts)

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Canvas Id",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Canvas Id"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Apparel
                </h5>
                <FormInput
                  name="name"
                  label="Product Canvas Id"
                  placeholder="e.g : Apple iMac"
                  containerClass={"mb-3"}
                  register={register}
                  key="productname"
                  errors={errors}
                  control={control}
                  onChange={(e) => setProductCanvasId(e.target.value)}
                />

                {/* Only show options when Canvas ID is entered */}
                {productCanvasId && (
                  <div className="mb-3">
                    <Form.Check
                      type="radio"
                      label="Single Color Garment"
                      name="option"
                      defaultValue="Masking"
                      checked={selectedOption === "Masking"}
                      onChange={handleOptionChange}
                    />
                    <Form.Check
                      type="radio"
                      label="Multi Color Garment"
                      name="option"
                      defaultValue="Images"
                      checked={selectedOption === "Images"}
                      onChange={handleOptionChange}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Product with Mask */}
        {selectedOption === "Masking" && productCanvasId && (
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <button
                    type="button"
                    className="btn btn-primary mb-3"
                    onClick={handleAddPart}
                  >
                    Add Part
                  </button>
                  {parts.map((part, index) => {
          const partIndex = index + 1;
          return (
            <Row key={index}>
              <Col lg={12}>
                <Card>
                  <Card.Body>
                    <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                      Part {partIndex}
                    </h5>
                    <FormInput
                      name={`parts[${index}].name`}
                      defaultValue={parts[index].name}
                      label="Part Namesss"
                      placeholder="e.g : Frontsss"
                      containerClass={"mb-3"}
                       register={register}
                      errors={errors}
                      control={control}
                    />


                    {/* Product Image Upload */}
                    <Form.Group className="mb-3 ">
                      <Form.Label>Product Image {partIndex}</Form.Label>
                      <FileUploader
                        onFileUpload={(files: any) => handleFileUpload(files[0], index)}
                      />
                    </Form.Group>



                   <Form.Check
  type="checkbox"
  label="Screen Printing"
  checked={part.screenPrinting}
  name="screenPrinting"
  onChange={() => handleCheckboxChange(index, "screenPrinting")}
  // onChange={(e) => {
  //   if(e.target.value == 'on') {
  //     // setScreen(true);
  //     console.log("ScreenPrinting",e.target.value);
  //   }
    
  // }}
  className="mb-2"
/>
<Form.Check
  type="checkbox"
  label="Embroidery"
  name="embroidery"
  checked={part.embroidery}
  onChange={() => handleCheckboxChange(index, "embroidery")}
  // onChange={() => {
  //   // setEmb(true);
  //   console.log("Embroidery");
  // }}
  className="mb-3"
/>

{/* Conditionally Render Screen Printing Design & Guideline Areas */}
{screen && (
  <>
    <h6 className="mt-3">Screen Printing</h6>
                          
    <FormInput
                      name={`parts[${index}].screenPrintingData.CanvasComponent${index + 1}.canvasSize`}
                      defaultValue={parts[index].screenPrintingData[`CanvasComponent${index + 1}`]?.canvasSize}
                      label={`Canvas Size ${index + 1}`}
                      placeholder="e.g : dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designAreaText`}
                      defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.designAreaText}
                      label={`Design Area ${index + 1}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.leftPercent`}
                          defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.designableArea.leftPercent}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.topPercent`}
                          defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.designableArea.topPercent}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.widthPercent`}
                          defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.designableArea.widthPercent}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.heightPercent`}
                          defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.designableArea.heightPercent}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                    {/* Guideboxes - Repeatable Section */}
                    {part.screenPrintingData[`DesignAreaComponent${index + 1}`].guideboxes.map(
                      (box: GuideBox, boxIndex: number) => {
                      
                        return (
                        <div key={boxIndex} className="row tool-apprealbordertwo">
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].guidelineBoxText`}
                            defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.guidelineBoxText}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          {/* Guidebox Area - Display in a single row */}
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].leftPercent`}
                                defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.leftPercent}
                                label="Left Percent"
                                placeholder="e.g : 0"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].topPercent`}
                                defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.topPercent}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].widthPercent`}
                                defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.widthPercent}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].heightPercent`}
                                defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.heightPercent}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-between align-items-center mt-2">
  <button
    type="button"
    className="btn btn-danger btn-sm"
    onClick={() => handleRemoveGuideBox(index, boxIndex, 'screenPrintingData')}
  >
    Remove Guidebox
  </button>
 
</div>
                        </div>
                        )
                      }
                    )}

                    <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      onClick={() => handleAddGuideBox(index,'screenPrintingData')}
                    >
                      Add Guidebox
                    </button>

  </>
)}

{/* Conditionally Render Embroidery Design & Guideline Areas */}
{emb && (
  <>
    <h6 className="mt-3">Embroidery</h6>
                          
    <FormInput
                      name={`parts[${index}].embroideryData.CanvasComponent${index + 1}.canvasSize`}
                      defaultValue={parts[index].embroideryData[`CanvasComponent${index + 1}`]?.canvasSize}
                      label={`Canvas Size ${index + 1}`}
                      placeholder="e.g : dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designAreaText`}
                      defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.designAreaText}
                      label={`Design Area ${index + 1}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.leftPercent`}
                          defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.designableArea.leftPercent}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.topPercent`}
                          defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.designableArea.topPercent}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.widthPercent`}
                          defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.designableArea.widthPercent}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.heightPercent`}
                          defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.designableArea.heightPercent}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                    {/* Guideboxes - Repeatable Section */}
                    {part.embroideryData[`DesignAreaComponent${index + 1}`].guideboxes.map(
                      (box: GuideBox, boxIndex: number) => (
                        <div key={boxIndex} className="row tool-apprealbordertwo" style={{ display: "none" }}>
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].guidelineBoxText`}
                            defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.guidelineBoxText}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          {/* Guidebox Area - Display in a single row */}
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].leftPercent`}
                                defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.leftPercent}
                                label="Left Percent"
                                placeholder="e.g : 0"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].topPercent`}
                                defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.topPercent}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].widthPercent`}
                                defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.widthPercent}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].heightPercent`}
                                defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.heightPercent}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                        </div>
                      )
                    )}

                    {/* <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      onClick={() => handleAddGuideBox(index,'embroideryData')}
                    >
                      Add Guidebox
                    </button> */}
                   

  </>
)}




                    {parts.length > 0 && productCanvasId && (
  <div className="text-end mb-3">
    <button
      type="button"
      className="btn w-sm btn-primary waves-effect waves-light me-2"
      onClick={handleAddPart}
    >
      Add Part
    </button>
    <button
      type="button"
      className="btn w-sm btn-danger waves-effect waves-light me-2"
      onClick={() => handleRemovePart(index)}
    >
      Remove Part
    </button>
  </div>
)}

                  </Card.Body>
                </Card>
              </Col>
            </Row>
          );
        })}


                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        {/* Product with Images */}
        {selectedOption === "Images" && productCanvasId && (
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                <div className="d-flex align-items-center flex-wrap gap-3">
  {/* Search */}
  <div className="me-3">
    <input
      type="search"
      className="form-control my-1 my-lg-0"
      placeholder="Search by Name or Style..."
      onChange={handleSearchChange}
    />
  </div>

  {/* Canvas ID Status filter */}
  <div className="d-flex align-items-center me-sm-3">
    <label htmlFor="canvas-status-select" className="me-2">Canvas Status</label>
    <select
      className="form-select my-1 my-lg-0"
      id="canvas-status-select"
      value={selectedCanvasStatus}
      onChange={handleCanvasStatusChange}
    >
      <option value="all">All</option>
      <option value="notassign">Not Assigned</option>
      <option value="assign">Assigned</option>
    </select>
  </div>

  {/* Products per page selector */}
  <div className="d-flex align-items-center me-sm-3">
    <label htmlFor="products-per-page" className="me-2">Show</label>
    <select
      className="form-select my-1 my-lg-0"
      id="products-per-page"
      value={productsPerPage}
      onChange={handleProductsPerPageChange}
    >
      <option value={10}>10</option>
      <option value={20}>20</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
    </select>
  </div>
</div>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Product</Form.Label>
                    <Form.Control
                      as="select"
                      value={selectedProductId}
                      onChange={(e) => {
                        handleProductSelect(Number(e.target.value))
                        console.log("ProductSelection",e.target.value)
                        
                      }}
                    
                    >
                      <option defaultValue="">Select a Product</option>
                      {products.map((product) => {
                        console.log("ProductName && product.id:",product.name, product.id);
                      
                        return (
                        <option key={product.id} value={product.id}>
                          {product.name}
                        </option>
                        )
                      })}
                    </Form.Control>
                  </Form.Group>
                  {selectedProduct && (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary mb-3"
                        onClick={handleAddPart}
                      >
                        Add Part
                      </button>
                      {parts.map((part, index) => (

                        
                        <div key={index} className="mb-3">
                          <h6>Part {index + 1}</h6>
                          <FormInput
                            name={`parts[${index}].name`}
                            defaultValue={parts[index].name}
                            label="Part Name"
                            placeholder="e.g : Front"
                            register={register}
                            errors={errors}
                            control={control}
                          />

                           <h6>Colors:</h6>
                           <div className="allcolors-canvasid">
                          {part.colors.map((color, colorIndex) => (
                            <div key={colorIndex} className="mb-3">
                              <span>{color}</span>
                              <FileUploader
                               onFileUpload={(files: any) =>
                                 handleFileUpload(files[0], index, color) // Pass file, part index, and color
                               }
                             />
                            </div>
                          ))}
                          </div>




                          <Form.Check
  type="checkbox"
  label="Screen Printing"
  checked={part.screenPrinting}
  name="screenPrinting"
  onChange={() => handleCheckboxChange(index, "screenPrinting")}
  className="mb-2"
/>
<Form.Check
  type="checkbox"
  label="Embroidery"
  name="embroidery"
  checked={part.embroidery}
  onChange={() => handleCheckboxChange(index, "embroidery")}
  className="mb-3"
/>


{screen && (
  <>
    <h6 className="mt-3">Screen Printing</h6>
                          
    <FormInput
                      name={`parts[${index}].screenPrintingData.CanvasComponent${index + 1}.canvasSize`}
                      defaultValue={parts[index].screenPrintingData[`CanvasComponent${index + 1}`]?.canvasSize}
                      label={`Canvas Size ${index + 1}`}
                      placeholder="e.g : dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designAreaText`}
                      defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.designAreaText}
                      label={`Design Area ${index + 1}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.leftPercent`}
                          defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.designableArea.leftPercent}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.topPercent`}
                          defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.designableArea.topPercent}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.widthPercent`}
                          defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.designableArea.widthPercent}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.heightPercent`}
                          defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.designableArea.heightPercent}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                    <div>
                    {part.screenPrintingData[`DesignAreaComponent${index + 1}`].guideboxes.map(
                      (box: GuideBox, boxIndex: number) => {
                        console.log(
                          `abdullah:`,
                          parts[index]?.screenPrintingData?.[`DesignAreaComponent${index + 1}`]
                            ?.guideboxes?.[boxIndex]?.leftPercent
                        );
                        return (
                        <div key={boxIndex} className="row tool-apprealbordertwo">
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].guidelineBoxText`}
                            defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.guidelineBoxText}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          {/* Guidebox Area - Display in a single row */}
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].leftPercent`}
                                defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.leftPercent}
                                label="Left Percent"
                                placeholder="e.g : 0"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].topPercent`}
                                defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.topPercent}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].widthPercent`}
                                defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.widthPercent}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].heightPercent`}
                                defaultValue={parts[index].screenPrintingData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.heightPercent}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-between align-items-center mt-2">
  <button
    type="button"
    className="btn btn-danger btn-sm"
    onClick={() => handleRemoveGuideBox(index, boxIndex, 'screenPrintingData')}
  >
    Remove Guidebox
  </button>
 
</div>
                        </div>
                        )
                      }
                    )}

                    <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      onClick={() => handleAddGuideBox(index,'screenPrintingData')}
                    >
                      Add Guidebox
                    </button>
                    </div>

  </>
)}

{/* Conditionally Render Embroidery Design & Guideline Areas */}
{emb && (
  <>
    <h6 className="mt-3">Embroidery</h6>
                          
    <FormInput
                      name={`parts[${index}].embroideryData.CanvasComponent${index + 1}.canvasSize`}
                      // defaultValue={parts[index]?.embroideryData[`CanvasComponent${index + 1}`]?.canvasSize}
                      defaultValue={
                        parts[index]?.embroideryData && 
                        parts[index]?.embroideryData[`CanvasComponent${index + 1}`] 
                          ? parts[index]?.embroideryData[`CanvasComponent${index + 1}`]?.canvasSize 
                          : ""
                      }
                      label={`Canvas Size ${index + 1}`}
                      placeholder="e.g : dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designAreaText`}
                      // defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.designAreaText}
                      defaultValue={
                        parts[index]?.embroideryData && 
                        parts[index]?.embroideryData[`DesignAreaComponent${index + 1}`] 
                          ? parts[index]?.embroideryData[`DesignAreaComponent${index + 1}`]?.designAreaText 
                          : ""
                      }
                      label={`Design Area ${index + 1}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.leftPercent`}
                          // defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.designAreaText.leftPercent}
                          defaultValue={
                            parts[index]?.embroideryData && 
                            parts[index]?.embroideryData[`DesignAreaComponent${index + 1}`] 
                              ? parts[index]?.embroideryData[`DesignAreaComponent${index + 1}`]?.designAreaText.leftPercent
                              : ""
                          }
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.topPercent`}
                          // defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.designAreaText.topPercent}
                          defaultValue={
                            parts[index]?.embroideryData && 
                            parts[index]?.embroideryData[`DesignAreaComponent${index + 1}`] 
                              ? parts[index]?.embroideryData[`DesignAreaComponent${index + 1}`]?.designAreaText.topPercent
                              : ""
                          }
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.widthPercent`}
                          // defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.designAreaText.widthPercent}
                          defaultValue={
                            parts[index]?.embroideryData && 
                            parts[index]?.embroideryData[`DesignAreaComponent${index + 1}`] 
                              ? parts[index]?.embroideryData[`DesignAreaComponent${index + 1}`]?.designAreaText.widthPercent
                              : ""
                          }
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.heightPercent`}
                          // defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.designAreaText.heightPercent}
                          defaultValue={
                            parts[index]?.embroideryData && 
                            parts[index]?.embroideryData[`DesignAreaComponent${index + 1}`] 
                              ? parts[index]?.embroideryData[`DesignAreaComponent${index + 1}`]?.designAreaText.heightPercent
                              : ""
                          }
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                    <div style={{ display: "none" }}>
                    {parts[index]?.embroideryData && part.embroideryData[`DesignAreaComponent${index + 1}`].guideboxes.map(
                      (box: GuideBox, boxIndex: number) => (
                        <div key={boxIndex} className="row tool-apprealbordertwo">
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].guidelineBoxText`}
                            defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.guidelineBoxText}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].leftPercent`}
                                defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.leftPercent}
                                label="Left Percent"
                                
                                placeholder="e.g : 0"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].topPercent`}
                                defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.topPercent}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].widthPercent`}
                                defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.widthPercent}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].heightPercent`}
                                defaultValue={parts[index].embroideryData[`DesignAreaComponent${index + 1}`]?.guideboxes[boxIndex]?.heightPercent}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                        </div>
                      )
                    )}

                    {/* <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      onClick={() => handleAddGuideBox(index,'embroideryData')}
                    >
                      Add Guidebox
                    </button> */}
                    </div>
                   

  </>
)}





                          
                         


                         
                        </div>
                      ))}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn btn-success waves-effect waves-light me-1"
              >
                Update
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default EditToolApparel;
